import React from 'react';

const Terminos = () => {
  return (
    <p style={{textAlign: 'center', width: '80%', margin: 'auto', fontSize: '24px', color: '#FFF'}}>
      
    </p>
  )
}

export default Terminos;